import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createUser } from "../api/main";
import Button from "../components/Button";
import { useAuth } from "../contexts/AuthContext";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const [creationError, setCreationError] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!!user) navigate("/");
  // eslint-disable-next-line
  }, [user]);

  const checkValid = () => {
    return email.length > 0 && password.length >= 8 && name.length > 0;
  };

  const handleSignup = () => {
    if (checkValid()) createUser(email, name, password).then(res => {
      if (!!res) navigate("/")
      else setCreationError(true);
    });
    else setError(true);
  };
  
  const handleEnter = e => {
    if (e.key === "Enter") handleSignup();
  };

  return (
    <div className="h-screen w-screen bg-hunter-green/[0.8] flex justify-center items-center">
      <div className="space-y-6 w-1/4">
        <p className="font-bold text-5xl text-white">Sign Up <span className="text-sm font-semibold hover:underline hover:cursor-pointer" onClick={() => navigate("/login")}>or login.</span></p>
        <div>
          <p className="font-semibold text-white">Email</p>
          <input type="text" value={email} onChange={e => setEmail(e.target.value)} className="w-full rounded-md" />
        </div>
        <div>
          <p className="font-semibold text-white">Name</p>
          <input type="text" value={name} onChange={e => setName(e.target.value)} className="w-full rounded-md" />
        </div>
        <div>
          <p className="font-semibold text-white">Password</p>
          <input type="password" value={password} onChange={e => setPassword(e.target.value)} className="w-full rounded-md" onKeyDown={handleEnter} />
          <p className="text-xs text-white">must be at least 8 characters long.</p>
        </div>
        <div className="flex justify-between items-center">
          <Button
            text="sign up"
            width="w-1/4"
            onHit={() => handleSignup()}
          />
        </div>
        {error && <p className="text-cardinal-red/[0.7] font-bold">Missing one or more fields.</p>}
        {creationError && <p className="text-cardinal-red/[0.7] font-bold">There was an issue creating your account.</p>}
      </div>
    </div>
  );
};

export default Signup;