import { db } from "./main";
import { getDocs, query, where, collection, doc, updateDoc } from "firebase/firestore";

export const fetchUsername = async (uid) => {
  try {
    const docs = await getDocs(query(collection(db, "actives"), where("uid", "==", uid)));
    const doc = docs.docs[0];
    return doc.data().name;
  } catch (e) {
    console.error(e);
  }
};

export const isAdmin = async (uid) => {
  try {
    const active = await getDocs(query(collection(db, "actives"), where("uid", "==", uid)));
    const data = active.docs[0].data();
    return !!data.admin;
  } catch (e) {
    console.error(e);
  }
};

export const fetchStarredPNMs = async (uid) => {
  try {
    const snapshot = await getDocs(query(collection(db, "pnms"), where("stars", "array-contains", uid)));
    const docs = snapshot.docs;
    const names = docs.map(d => {
      const data = d.data();
      return data.name;
    });
    return names;
  } catch (e) {
    console.error(e);
  }
};

export const fetchLikedPNMs = async (uid) => {
  try {
    const snapshot = await getDocs(query(collection(db, "pnms"), where("likes", "array-contains", uid)));
    const docs = snapshot.docs;
    const names = docs.map(d => {
      const data = d.data();
      return data.name;
    });
    return names;
  } catch (e) {
    console.error(e);
  }
};

export const fetchDislikedPNMs = async (uid) => {
  try {
    const snapshot = await getDocs(query(collection(db, "pnms"), where("dislikes", "array-contains", uid)));
    const docs = snapshot.docs;
    const names = docs.map(d => {
      const data = d.data();
      return data.name;
    });
    return names;
  } catch (e) {
    console.error(e);
  }
};

export const convertUIDsToNames = async (uids) => {
  try {
    const names = [];
    uids.forEach(async (uid) => {
      const snapshot = await getDocs(query(collection(db, "actives"), where("uid", "==", uid)));
      const doc = snapshot.docs[0];
      const name = doc?.data().name;
      names.push(name);
    });
    return names;
  } catch (e) {
    console.error(e);
  }
};

export const getAllActives = async () => {
  try {
    const snapshot = await getDocs(query(collection(db, "actives")));
    const docs = snapshot.docs;
    const data = docs.map(d => ({ id: d.id, ...d.data() }));
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const promoteToAdmin = async (activeId) => {
  try {
    const activeRef = doc(db, "actives", activeId);
    await updateDoc(activeRef, { admin: true });
  } catch (e) {
    console.error(e);
  }
};

export const demoteFromAdmin = async (activeId) => {
  try {
    const activeRef = doc(db, "actives", activeId);
    await updateDoc(activeRef, { admin: false });
  } catch (e) {
    console.error(e);
  }
};