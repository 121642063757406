import React, { useState, useEffect } from "react";
import { getAllActives } from "../api/actives";
import Nav from "../components/Nav";
import ActiveRow from "../components/ActiveRow";

const Actives = () => {
    const [actives, setActives] = useState([]);

    useEffect(() => {
        getAllActives().then(res => setActives(res));
    }, []);

    return (
        <div className="h-screen w-screen bg-gray-200 pt-20 overflow-y-scroll">
            <Nav />
            <div className="w-2/3 mx-auto space-y-4 px-14 pb-14">
                {actives.map(a => (
                    <ActiveRow key={a.id} uid={a.id} name={a.name} admin={!!a.admin} />
                ))}
            </div>
        </div>
    );
};

export default Actives;