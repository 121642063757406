import React from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Submit from "./pages/Submit";
import Login from "./pages/Login";
import Gallery from "./pages/Gallery";
import Actives from "./pages/Actives";
import Signup from "./pages/Signup";
import { auth } from "./api/main";
import { fetchUsername } from "./api/actives";
import { useAuth } from "./contexts/AuthContext";
import PhoneOverlay from "./components/PhoneOverlay";

function App() {
  const { setUser, setUsername } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  auth.onAuthStateChanged(user => {
    if (!!!user && location.pathname !== "/signup" && location.pathname !== "/login") {
      navigate("/login")
    } else if (!!user) {
      setUser(user);
      fetchUsername(user.uid).then(name => setUsername(name));
    }
  });

  if (window.innerWidth < 480) return <PhoneOverlay />;

  return (
    <Routes>
      <Route path="/actives" element={<Actives />} />
      <Route path="/pnm" element={<Submit />} />
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Gallery />} />
      <Route path="/signup" element={<Signup />} />
    </Routes>
  );
}

export default App;
