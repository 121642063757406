import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { login, forgotPassword } from "../api/main";
import Button from "../components/Button";
import { useAuth } from "../contexts/AuthContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState(false);
  const [forgotPasswordError, setForgotPasswordError] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!!user) navigate("/");
  // eslint-disable-next-line
  }, [user]);

  const checkValid = () => {
    return email.length > 0 && password.length >= 0;
  };

  const handleLogin = () => {
    if (checkValid()) login(email, password).then(res => {
      if (!!res) navigate("/")
      else setError(true);
    });
    else setError(true);
  };

  const handleEnter = e => {
    if (e.key === "Enter") handleLogin();
  };

  const handleForgotPassword = () => {
    if (email.length === 0) {
      setForgotPasswordError(true);
      return;
    }

    forgotPassword(email).then(res => setResetPasswordEmailSent(res));
  };

  return (
    <div className="h-screen w-screen bg-hunter-green/[0.8] flex justify-center items-center">
      <div className="space-y-6 w-1/4">
        <p className="font-bold text-5xl text-white">Login <span className="text-sm font-semibold hover:underline hover:cursor-pointer" onClick={() => navigate("/signup")}>or sign up.</span></p>
        <div>
          <p className="font-semibold text-white">Email</p>
          <input type="text" value={email} onChange={e => setEmail(e.target.value)} className="w-full rounded-md" />
        </div>
        <div>
          <p className="font-semibold text-white">Password</p>
          <input type="password" value={password} onChange={e => setPassword(e.target.value)} className="w-full rounded-md" onKeyDown={handleEnter} />
        </div>
        <div className="flex justify-between items-center">
          <Button
            text="login"
            width="w-1/4"
            onHit={() => handleLogin()}
          />
        </div>
        {!resetPasswordEmailSent ? (
          <p
            className="text-center text-white underline hover:text-cardinal-red/[0.7] cursor-pointer"
            onClick={handleForgotPassword}
          >
            Forgot password?
          </p>
        ) : (
          <p className="text-white">Email sent!</p>
        )}
        {error && <p className="text-cardinal-red/[0.7] font-bold">Missing or incorrect credentials.</p>}
        {forgotPasswordError && <p className="text-cardinal-red/[0.7] font-bold">Need to specify email.</p>}
      </div>
    </div>
  );
};

export default Login;